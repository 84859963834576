<template>
    <div>{"code":403,"msg":"暂无权限"}</div>
</template>

<script>
    export default {
        name: "noPermission"
    }
</script>

<style scoped>

</style>
